<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="onAddressSelect($event)"
  [displayWith]="displayFn"
>
  <mat-option
    class="mat-option__dadata-address mat-option"
    *ngFor="let option of suggestions | async"
    [value]="option"
  >
    {{option.value}}
  </mat-option>
</mat-autocomplete>

<div [formGroup]="parentFormGroup">
  <textarea
    autocomplete="no-autocomplete"
    [id]="textareaId"
    [formControlName]="textareaControlName"
    placeholder="Начните вводить адрес"
    class="form-control form-control-sm {{textareaClass}}"
    [matAutocomplete]="auto"
    [disabled]="disabled"
    (input)="onAddressInput($event)"
  ></textarea>
</div>
