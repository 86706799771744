<div class="header">
  <div class="head_menu {{!isSidebarOpened ? 'hide' : ''}}">
    <a routerLink="/">
      <div class="logo"></div>
    </a>

    <div *ngIf="user" class="show_hide_menu {{!isSidebarOpened ? 'hide' : ''}}" (click)="onShowMenuClick()"></div>
  </div>

  <div class="right_block">
    <div class="phone">
      <span class="caption">
        <ng-container
          *ngIf="client"
        >
          <strong>{{client.full_name}}</strong>
          <br>
        </ng-container>
        Клиентский отдел: <a href="tel:+74993223295">+7 (499) 322-32-95</a>
      </span>
    </div>

    <div *ngIf="isAuthenticated" #profilePopup class="profile call_popupcloud {{isProfilePopupOpened ? 'open' : ''}}" (click)="isProfilePopupOpened = !isProfilePopupOpened">
      <div class="ava">
        <img src="./assets/img/icons/a-user.svg" alt="">
      </div>

      <div class="popupcloud {{isProfilePopupOpened ? 'active' : ''}}">
        <span>Ваш номер клиента: {{client.client_uid_old}}</span>
        <ul>
          <li>
            <a routerLink="/profile">Редактировать профиль</a>
          </li>
          <li>
            <a class="exit" (click)="onLogoutClick()">Выход</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
