<app-header class="app-header" *ngIf="!noTemplate"></app-header>

<div class="page" *ngIf="!noTemplate">
  <app-sidebar *ngIf="user"></app-sidebar>

  <div class="page-content {{!user ? 'page-content__no-sidebar' : ''}} {{(sidebarIsMinified && !isMobileWidth) ? 'page-content__sidebar-minified' : ''}}">
    <router-outlet></router-outlet>
  </div>
</div>

<router-outlet *ngIf="noTemplate"></router-outlet>
