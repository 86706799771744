import {Component} from '@angular/core';
import {BaseFilterComponent} from './base-filter.component';

@Component({
  selector: 'app-ngx-datatable-filter-is-sameday',
  template: `
    <form [formGroup]="formFilter">
      <select class="form-control form-control-sm" formControlName="filter" name="role" (change)="triggerChange()">
        <option *ngFor="let item of isSamedayList" value="{{item.id}}">{{item.label}}</option>
      </select>
    </form>
  `
})
export class FilterIsSamedayComponent extends BaseFilterComponent {
  public readonly isSamedayList = [
    {id: null, label: '=== Все ==='},
    {id: 0, label: 'Обычные'},
    {id: 1, label: 'День в день'},
  ];

  constructor() {
    super();
  }
}
