import {Component} from '@angular/core';
import {BaseFilterComponent} from './base-filter.component';

@Component({
  selector: 'app-ngx-datatable-filter-string',
  template: `
    <form [formGroup]="formFilter">
      <input type="text" class="form-control form-control-sm" (input)="onInput($event)" formControlName="filter">
    </form>
  `
})
export class FilterStringComponent extends BaseFilterComponent {}
