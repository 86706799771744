export class AccessTokenModel {
  constructor(private _token: string,
              private _tokenExpirationDate: Date) {
  }

  get token() {
    if (!this._tokenExpirationDate
      || new Date() > this._tokenExpirationDate) {
      return null;
    }

    return this._token;
  }

  get expirationDate() {
    return this._tokenExpirationDate;
  }

  get expirationInSeconds() {
    return (new Date(this._tokenExpirationDate).getTime() -
      new Date().getTime()) / 1000;
  }
}
