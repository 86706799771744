import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscriber, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {EventerService} from './eventer.service';
import {Router} from '@angular/router';
import {NotifierService} from "./notifier.service";

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(
    private http: HttpClient,
    private eventer: EventerService,
    private router: Router,
    private notifier: NotifierService
  ) {
  }

  /**
   * GET запрос
   *
   * @param url
   * @param params
   */
  protected get(url, params = null): Observable<any> {
    return new Observable((observer) => {

      this.http.get<any>(environment.apiUrl + url, {params: params})
        .pipe(catchError(this.handleError.bind(this, observer)))
        .subscribe((data: ApiResult) => {
          observer.next(data.response);
          observer.complete();
        });
    });
  }

  /**
   * POST запрос
   *
   * @param url
   * @param param
   */
  protected post(url, param): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.apiUrl + url, param)
        .pipe(catchError(this.handleError.bind(this, observer)))
        .subscribe((data: ApiResult) => {
          observer.next(data.response);
          observer.complete();
        });
    });
  }

  /**
   * DELETE запрос
   *
   * @param url
   * @param params
   */
  protected delete(url, params = null): Observable<any> {
    return new Observable((observer) => {
      this.http.delete(environment.apiUrl + url, {params: params})
        .pipe(catchError(this.handleError.bind(this, observer)))
        .subscribe((data: ApiResult) => {
          observer.next(data.response);
          observer.complete();
        });
    });
  }

  protected handleError(observer: Subscriber<any>, e) {
    if (e.error.response.error === 'This action is unauthorized.') {
      this.notifier.openNotifier('Ошибка прав доступа. Перезайдите в систему!',
        null, {class: 'danger'});
    } else if (e.status === 501) {
      // this.storage.remove(this.TOKEN_KEY);
      // this.eventer.currentUserData.emit(false);
      // if (e.error.response.error === 'Unauthorized') {
      //   this.router.navigate(['login']);
      // }
    } else {
      if (e.error.message) {
        this.eventer.apiError.emit(e.error.message);
      } else if (e.error.response) {
        this.eventer.apiError.emit(e.error.response.error);
      }
    }

    // return error to observer
    observer.error(e);

    return throwError(e);
  }

  /**
   * Подписчик на ошибки апи
   */
  public errorEmit() {
    return this.eventer.apiError;
  }

  /**
   * Возвращает строку для GET запроса из объекта
   * @param params
   */
  protected getQueryStringFromArray(params) {
    return Object.keys(params).map(key => key + '=' + params[key]).join('&');
  }

  /**
   * Получить список хабов
   */
  public getHubs() {
    return this.get('/client/hubs');
  }

  /**
   * Получить список всех хабов
   */
  public getAllHubs() {
    return this.get('/client/hubs/getAllHubs');
  }
}

export class ApiResult {
  public response: any;
  public status: number;
}
