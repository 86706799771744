import {AngularYandexMapsModule, YA_CONFIG} from 'angular8-yandex-maps';
import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HeaderComponent} from './layout/header/header.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {SharedModule} from './shared/shared.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import * as appReducer from './store/app.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './auth/store/auth.effects';
import {HttpRequestInterceptor} from './interceptor/http-request.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';

import localeRuRu from '@angular/common/locales/ru';
import localeRuRuExtra from '@angular/common/locales/extra/ru';
import {environment} from '../environments/environment';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {UserEffects} from './store/user/user.effects';
import {BaseErrorHandler} from './shared/error-handler/base-error-handler';

registerLocaleData(localeRuRu, localeRuRuExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    PageNotFoundComponent
  ],
  imports: [
    AngularYandexMapsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot(appReducer.appReducer),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    EffectsModule.forRoot([AuthEffects, UserEffects]),
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: YA_CONFIG,
      useValue: {
        apikey: environment.yandex_api_key,
        lang: 'ru_RU',
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpRequestInterceptor,
    },
    {
      provide: ErrorHandler,
      useClass: BaseErrorHandler,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
