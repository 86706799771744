import {BaseModel} from './base.model';

export class HubDeliveryIntervalModel extends BaseModel {
  public static NOT_PRESENT_INTERVAL_ID: number = 6;

  id: number;
  hub_id: number;
  time_start: string;
  time_end: string;
  hours: [
    (number|string),
    (number|string)
  ];
  hours_full: [
    (string),
    (string)
  ];
  is_default_interval?: boolean;
  shift_number: number;
  is_sameday: number;
  label: string;
  label_short: string;

  constructor(data: any) {
    super(data);

    this.is_default_interval = data.hasOwnProperty('is_default_interval') ? !!this.is_default_interval : false;

    if (data.id === HubDeliveryIntervalModel.NOT_PRESENT_INTERVAL_ID) {
      this.label = "Не передали интервал";
      this.label_short = "Нет интервала";
    }
  }
}
