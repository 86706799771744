import {ClientModel} from './client.model';
import {OrderBillModel} from './order-bill.model';
import {OrderOptionModel} from './order-option.model';
import {OrderTargetModel} from './order-target.model';
import {OrderGoodModel} from './order-good.model';
import {OrderBarcodeModel} from './order-barcode.model';
import {HubModel} from './hub.model';

export class OrderModel {
  // Новая заявка
  public static STATUS_NEW = 1;
  // Заявка принята
  public static STATUS_ACCEPTED = 2;
  // На складе
  public static STATUS_STORAGE = 3;
  // На доставке
  public static STATUS_DELIVERY = 4;
  // Доставлена
  public static STATUS_FINISH = 5;
  // Част. отказ
  public static STATUS_FAIL_PARTIAL = 6;
  // Полн. отказ
  public static STATUS_REFUSE = 7;
  // Отмена
  public static STATUS_CANCEL = 8;
  // Перемещение между хабами
  public static STATUS_TRANSIT = 9;

  // До скольки можно создавать заказ на тот же день
  public static MAX_HOUR_CREATE_SAMEDAY_DELIVERY = 15;

  public static MAX_HOUR_CREATE_TODAY_DELIVERY = 3;

  // справочник ставок НДС
  public static ndsRates = [
    {id: 2, name: 'Без НДС'},
    {id: 3, name: 'НДС 10%'},
    {id: 5, name: 'НДС 10/110'},
    {id: 6, name: 'НДС 0%'},
    {id: 7, name: 'НДС 20%'},
    {id: 8, name: 'НДС 20/120'},
  ];

  public id: number;
  public courier_id: number;
  public inner_n: string;
  public delivery_date: string;
  public delivery_time: number;
  public status: number;
  public request: string;
  public created_at: Date;
  public updated_at: Date;
  public uid: string;
  public client_id: number;
  public client_uid: string;
  public barcode: string;
  public been_on_warehouse: number;
  public zone_id: number;
  public returned_courier_id: number;
  public returned_storage: number;
  public sync_date: Date;
  public sms_counter: number;
  public substatus_id: number;
  public is_regional: number;

  public bill: OrderBillModel;
  public option: OrderOptionModel;
  public target: OrderTargetModel;
  public client: ClientModel;
  public goods: OrderGoodModel[];
  public all_goods: OrderGoodModel[];
  public bar_codes: OrderBarcodeModel[];

  // public log_status: OrderLogStatus[];

  // public courier: User;
  // public return_courier: User;

  public vendor_code: string;

  public hub_origin_id?: number;
  public hub_destination_id?: number;
  public hub_current_id?: number;

  public hub_origin?: HubModel;
  public hub_destination?: HubModel;
  public hub_current?: HubModel;

  public is_sameday: number;
  public shift_number: number;
}
