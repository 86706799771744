import {Injectable} from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import {AccessTokenModel} from '../shared/models/access-token.model';
import * as AuthActions from '../auth/store/auth.actions';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private token: AccessTokenModel;

  constructor(
    // public errorDialogService: ErrorDialogService
    private store: Store<fromApp.AppState>
  ) {
    this.store.select('auth')
      .subscribe((userState) => {
          this.token = userState.token;
        }
      )
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.token && this.token.token) {
      request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + this.token.token)});
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
    }

    request = request.clone({headers: request.headers.set('Accept', 'application/json')});

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
          if (event.body.status === 402 || event.body.status === 404) {
            // this.errorDialogService.openDialog(event);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('1 event error API--->>>', error);
        const data = {
          reason: error && error.error && error.error.response && error.error.response.message ? error.error.response.message : '',
          status: error.status
        };
        if (error.error.response.error === 'Unauthorized') {
          this.store.dispatch(new AuthActions.Logout());

          return throwError(error);
        }
        // this.errorDialogService.openDialog(data);
        return throwError(error);
      }));
  }
}
