<div *ngIf="loading" class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>

<div class="left_menu {{!sidebarIsOpened ? 'hide' : ''}} {{sidebarMobileIsOpened ? 'left-menu__mobile-open' : ''}}">
  <ng-container *ngFor="let item of menu; let parentIndex = index;">
    <div
      *ngIf="hasChildren(item)"
      [className]="'parent' + (parentIsActive(item.path) ? ' active' : '')"
    >
      <div (click)="onParentClick(item.path)" class="menu_head"></div>

      <i [className]="'icon fa ' + item.icon"></i>
      <h3 [innerHTML]="item.title"></h3>

      <div *ngIf="hasChildren(item)" [className]="'sub_menu' + (parentIsActive(item.path) ? ' active' : '')">
        <div class="pl-3" *ngFor="let submenu of item.items">
          <i [className]="'submenu-icon icon fa ' + submenu.icon"></i>

          <a *ngIf="!submenu.simpleLink"
             [className]="getActiveSubItem(submenu) ? 'active' : '' "
             [routerLink]="submenu.path"
             [innerHTML]="submenu.title"
          >
          </a>

          <a *ngIf="submenu.simpleLink"
             [href]="submenu.path"
             [innerHTML]="submenu.title"
          >
          </a>
        </div>
      </div>
    </div>

    <a
      *ngIf="!hasChildren(item)"
      [className]="'parent parent-no-children' + (parentIsActive(item.path) ? ' active' : '')"
      [routerLink]="item.path"
    >
      <div (click)="onParentClick(item.path)" class="menu_head"></div>

      <i [className]="'icon fa ' + item.icon"></i>
      <h3 [innerHTML]="item.title"></h3>
    </a>
  </ng-container>
</div>
