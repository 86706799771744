import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as UserActions from './user.actions';
import {defer} from 'rxjs';
import {Store} from '@ngrx/store';
import * as AppReducer from '../app.reducer';

@Injectable()
export class UserEffects {
  @Effect({ dispatch: false })
  setSidebarMinified = this.actions$.pipe(
    ofType(UserActions.SET_SIDEBAR_MINIFIED),
    tap((data: UserActions.UserActions) => {
      localStorage.setItem('sidebarIsMinified', data.payload ? '1' : '0');
    })
  );

  @Effect()
  init$ = defer(() => {
    this.store.dispatch(new UserActions.SetSidebarMinified(!!parseInt(localStorage.getItem('sidebarIsMinified'))));
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppReducer.AppState>
  ) {}
}
