import {BaseModel} from './base.model';
import {HubDeliveryIntervalModel} from './hub-delivery-interval.model';

export class HubModel extends BaseModel {
  // Собственные хабы
  public static readonly TYPE_NATIVE = 1;

  // ID хаба Москвы
  public static readonly HUB_MSK = 1;

  // ID хаба Спб
  public static readonly HUB_SPB = 2;

  public name: string;
  public full_name: string;
  public full_name_id: string;
  public map_zoom: number;
  public map_center_lat: number;
  public map_center_lon: number;
  public delivery_intervals?: HubDeliveryIntervalModel[];
  public default_delivery_interval?: HubDeliveryIntervalModel;
  public type: number;

  constructor(data: any) {
    super(data);

    this.full_name_id = this.name + ' [' + this.id + ']';

    if (data.hasOwnProperty('delivery_intervals') && data.delivery_intervals.length) {
      this.delivery_intervals = data.delivery_intervals.map(interval => {
        return new HubDeliveryIntervalModel(interval);
      });

      this.default_delivery_interval = this.delivery_intervals.find((interval: HubDeliveryIntervalModel) => {
        return interval.is_default_interval;
      });
    }
  }
}
