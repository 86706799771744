import {ApiService} from '../shared/services/api.service';
import {Injectable} from '@angular/core';
import {OrderModel} from "../shared/models/order.model";
import {HubModel} from "../shared/models/hub.model";

@Injectable()
export class ActsApiService extends ApiService {
  /**
   * Список актов
   * @param params
   */
  public getActs(params) {
    return this.get('/client/transceiver-acts?' + this.getQueryStringFromArray(params));
  }

  /**
   * Статусы заказов
   */
  public getOrderStatuses() {
    return this.get('/client/orders/statuses');
  }

  /**
   * Заказы в акте
   * @param actId
   */
  public getOrdersInAct(actId: string) {
    return this.get('/client/transceiver-acts/ordersInAct/' + actId);
  }

  /**
   * Получение акта для печати
   * @param actId
   */
  public getActForPrint(actId: string) {
    return this.get('/client/transceiver-acts/print/' + actId);
  }

  /**
   * Создание акта акта приёма-передачи
   * @param uids
   * @param hubId
   */
  public createAct(uids: string [], hubId = HubModel.HUB_MSK) {
    return this.post('/client/transceiver-acts/create', {uids, hub_id: hubId});
  }

  /**
   * Получение заказов клиента
   * со статусами 2 и 3
   * и пустым transceiver_id
   * @param params
   */
  public getOrdersClient(params) {
    return this.get('/client/transceiver-acts/orders?' + this.getQueryStringFromArray(params));
  }

  /**
   * Список заказов в статусах 1 и 2
   * @param params
   */
  public getOrdersInFirstStatuses(params) {
    return this.get('/client/orders?' +
      this.getQueryStringFromArray(params) +
      '&order_statuses[]=' + OrderModel.STATUS_NEW +
      '&order_statuses[]=' + OrderModel.STATUS_ACCEPTED
    );
  }

  /**
   * Добавление заказов в акт
   * @param params
   */
  public addToAct(params) {
    return this.post('/client/transceiver-acts/add', params);
  }

  /**
   * Смена города акта
   * @param params
   */
  changeActCity(params) {
    return this.post('/client/transceiver-acts/changeCity', params);
  }
}
