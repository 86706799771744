import * as UserActions from './user.actions';

export interface State {
  sidebarIsMinified: boolean;
}

const initialState: State = {
  sidebarIsMinified: false
};

export function userReducer(
  state = initialState,
  action: UserActions.UserActions
) {
  switch (action.type) {
    case UserActions.SET_SIDEBAR_MINIFIED:
      return {
        ...state,
        sidebarIsMinified: action.payload
      };
    default:
      return state;
  }
}
