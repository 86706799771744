import {FormControl, FormGroup} from '@angular/forms';
import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-base-filter-component',
  template: ''
})
export class BaseFilterComponent {
  constructor() {
    this.formFilter = new FormGroup({
      filter: new FormControl(this.value)
    });
  }

  @Input() public filter: string;
  @Input() public value: any;
  @Output('onChange') emitToParent = new EventEmitter<string>();

  public formFilter: FormGroup;

  public enableClearing = false;
  private onInputTimer;

  public onInput(event) {
    this.clearTimeout();

    this.onInputTimer = setTimeout(() => {
      this.triggerChange();
    }, 500);
  }

  public onChange(event) {
    this.triggerChange();
  }

  public triggerChange(): void {
    this.clearTimeout();

    const value = this.formFilter.get('filter').value;
    this.value = value;

    this.emitToParent.emit(value);
  }

  public clearTimeout() {
    if (this.onInputTimer) {
      clearTimeout(this.onInputTimer);
      this.onInputTimer = null;
    }
  }
}
