import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, map, switchMap, tap, throttleTime} from 'rxjs/operators';
import {DadataService} from '../../services/dadata.service';
import {DadataSuggestionModel} from './dadata-suggestion.model';
import {async} from 'rxjs/internal/scheduler/async';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dadata-address',
  templateUrl: './dadata-address.component.html',
  styleUrls: ['./dadata-address.component.scss'],
})
export class DadataAddressComponent implements OnInit {

  @Input() parentFormGroup: FormGroup;
  @Input() textareaControlName: string;
  @Input() textareaClass: string;

  @Input() textareaId: string;
  @Input() disabled: boolean = false;

  public suggestions: Observable<any>;
  public loadingSuggestions: boolean = false;
  addressInput$ = new Subject<string>();

  @Output('onSelect') emitToParent = new EventEmitter<DadataSuggestionModel>();
  @Output('onChange') emitChangeToParent = new EventEmitter<string>();

  constructor(private dadataService: DadataService) { }

  ngOnInit() {
    this.loadAddress();
  }

  private loadAddress() {
    this.suggestions = this.addressInput$.pipe(
      distinctUntilChanged(),
      throttleTime(500, async, {leading: false, trailing: true}),
      tap(() => this.loadingSuggestions = true),
      switchMap(
        term => this.dadataService.getSuggestions({'address': term})
        .pipe(
          catchError(() => of([])), // empty list on error
          tap((suggests) => {
            this.loadingSuggestions = false
          })
        )
      )
    );
  }

  onAddressInput(event) {
    this.emitChangeToParent.emit(event.target.value);
    this.addressInput$.next(event.target.value);
  }

  onAddressSelect(event: MatAutocompleteSelectedEvent) {
    this.emitToParent.emit(event.option.value);
  }

  /**
   * Как отобразить выбранное значение
   * @param value
   */
  displayFn(value): string {
    if (!value) {
      return null;
    }

    // если объект дадаты с адресом
    if (value.hasOwnProperty('value')) {
      return value.value;
    }

    // если просто строка (при инициализации, например)
    return value;
  }
}
