import { Injectable } from '@angular/core';
import { MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private _snackBar: MatSnackBar) { }

  openNotifier(message: string, action = 'Ок', settings: any = {}) {
    let panelClass: any = {
      danger: 'danger-notifier-service',
      success: 'success-notifier-service',
    };

    this._snackBar.open(message, action, {
      panelClass: settings.class ? panelClass[settings.class] : '',
      duration: settings.duration ? settings.duration : 5000,
      horizontalPosition: settings.horizontal ? settings.horizontal : this.horizontalPosition,
      verticalPosition: settings.vertical ? settings.vertical : this.verticalPosition,
    });
  }
}
