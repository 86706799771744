import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {UserModel} from '../../shared/models/user.model';
import {EventerService} from '../../shared/services/eventer.service';
import {Store} from '@ngrx/store';
import * as AppReducer from '../../store/app.reducer';
import {WindowHelper} from '../../shared/helpers/window.helper';

declare interface RouteInfo {
  path: string;
  title: string;
  icon?: string;
  problemsCount?: boolean;
  urlDiversion: boolean;
  simpleLink?: boolean;
  view: (user: UserModel) => boolean;
  items?: RouteInfo[];
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy {
  menu: RouteInfo[];
  public user: UserModel;
  public loading = false;
  private routerSubscription: Subscription;
  private sidebarToggleSubscription: Subscription;
  private windowResizeSubscription: Subscription;

  private activeRootItem: RouteInfo;
  private activeItem: RouteInfo;

  public activatedParentItems: string[] = [];

  /** в низких разрешениях экрана - отображается ли сайдбар */
  public sidebarIsOpened: boolean = false;
  public sidebarMobileIsOpened: boolean = false;

  /** состояние боковой панели из настроек пользователя */
  public sidebarIsMinified: boolean = false;

  private menuSource: RouteInfo[] = [
    {
      title: 'Заказы',
      path: '/orders',
      urlDiversion: false,
      icon: 'fa-shopping-cart',
      view: (user: UserModel) => true,
      items: [
        {
          path: '/orders/new',
          title: 'Новый заказ',
          icon: 'fa-plus-square',
          urlDiversion: false,
          view: (user: UserModel) => true
        },
        {
          path: '/orders/excel',
          title: 'Импорт XLS',
          icon: 'fa-upload',
          urlDiversion: false,
          view: (user: UserModel) => true
        },
        {
          path: '/orders/list',
          title: 'Все заказы',
          icon: 'fa-list-ul',
          urlDiversion: false,
          view: (user: UserModel) => true,
          items: [
            {
              path: '/orders/view',
              title: 'Редактирование заказа',
              icon: 'fa-list-ul',
              urlDiversion: false,
              view: (user: UserModel) => true,
            }
          ]
        },
      ]
    },
    {
      title: 'Акты приёма-передачи',
      path: '/acts',
      urlDiversion: false,
      icon: 'fa-list-alt',
      view: (user: UserModel) => true,
      items: [
        {
          path: '/acts/new',
          title: 'Создать акт',
          icon: 'fa-plus-square',
          urlDiversion: false,
          view: (user: UserModel) => true
        },
        {
          path: '/acts/list',
          title: 'Список актов',
          icon: 'fa-list-ul',
          urlDiversion: false,
          view: (user: UserModel) => true
        },
      ]
    },
    // {
    //   title: 'Отчётные документы',
    //   path: '/reports',
    //   urlDiversion: false,
    //   icon: 'fa-table',
    //   view: (user: UserModel) => true,
    //   items: [
    //     {
    //       path: '/reports/money',
    //       title: 'Отчеты о приеме ДС<br>и доставке заказов',
    //       icon: 'fa-file-text',
    //       urlDiversion: false,
    //       view: (user: UserModel) => true
    //     }
    //   ]
    // },
    {
      title: 'Забор/Привоз',
      path: '/zorders',
      urlDiversion: false,
      icon: 'fa-exchange',
      view: (user: UserModel) => true,
      items: [
        {
          path: '/zorders/new',
          title: 'Новая заявка',
          icon: 'fa-plus-square',
          urlDiversion: false,
          view: (user: UserModel) => true
        },
        {
          path: '/zorders',
          title: 'Заявки на заборы',
          icon: 'fa-list-ul',
          urlDiversion: false,
          view: (user: UserModel) => true
        },
        {
          path: '/zorders/warehouses',
          title: 'Склады',
          icon: 'fa-list-ul',
          urlDiversion: false,
          view: (user: UserModel) => true
        }
      ]
    },
    {
      title: 'Возвраты',
      path: '/returns',
      urlDiversion: false,
      icon: 'fa-undo',
      view: (user: UserModel) => true
    },
    {
      title: 'Новости',
      path: '/news',
      urlDiversion: false,
      icon: 'fa-bullhorn',
      view: (user: UserModel) => true
    },
    {
      title: 'Регламент<br>предоставления услуг',
      path: '/documents',
      urlDiversion: false,
      icon: 'fa-info-circle',
      view: (user: UserModel) => true
    },
    // {
    //   title: 'Карты',
    //   path: '/maps',
    //   urlDiversion: false,
    //   icon: 'fa-map-o',
    //   view: (user: UserModel) => true,
    //   items: [
    //     {
    //       path: '/maps/sameday-map',
    //       title: 'Доставка День в день',
    //       icon: 'fa-location-arrow',
    //       urlDiversion: false,
    //       view: (user: UserModel) => true
    //     },
    //   ]
    // },
    // {
    //   title: 'Инструкции',
    //   path: '/instructions',
    //   urlDiversion: false,
    //   icon: 'fa-question-circle',
    //   view: (user: UserModel) => true,
    //   items: [
    //     {
    //       path: '/instructions/create-new-order',
    //       title: 'Оформление новой заявки',
    //       icon: 'fa-youtube-play',
    //       urlDiversion: false,
    //       view: (user: UserModel) => true
    //     },
    //     {
    //       path: '/instructions/downloading-agent-reports',
    //       title: 'Скачивание отчётов агента',
    //       icon: 'fa-file-text',
    //       urlDiversion: false,
    //       view: (user: UserModel) => true
    //     },
    //   ]
    // },
  ];

  hasExtension = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);

  constructor(
    private router: Router,
    private eventerService: EventerService,
    private store: Store<AppReducer.AppState>,
    private title: Title
  ) {
  }

  ngOnInit() {
    this.store.select('auth')
      .subscribe((userState) => {
          this.user = userState.user;

          if (this.user) {
            this.menu = this.menuSource;
          } else {
            this.menu = [];
          }

          this.setTitleFromMenu();
          this.setActiveParent();
        }
      )

    this.store.select('user')
      .subscribe((userState) => {
        this.sidebarIsMinified = userState.sidebarIsMinified;

        this.updateSidebarStates();
      })

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidebarMobileIsOpened = false;
        this.activeRootItem = null;
        this.activeItem = null;
        this.activatedParentItems = [];

        this.setTitleFromMenu();
        this.setActiveParent();

        this.updateSidebarStates();
      }
    })

    this.sidebarToggleSubscription = this.eventerService.adminSidebarToggle.subscribe(opened => {
      this.sidebarIsOpened = opened;
      this.sidebarMobileIsOpened = opened;

      this.setActiveParent();
    })

    this.windowResizeSubscription = this.eventerService.windowResize.subscribe(() => {
      this.sidebarMobileIsOpened = false;
      this.updateSidebarStates();
    })

    this.loading = false;
  }

  private updateSidebarStates() {
    if (!WindowHelper.isMobileWidth() && !this.sidebarIsMinified) {
      this.sidebarIsOpened = true;
    } else if (WindowHelper.isMobileWidth()) {
      this.activatedParentItems = [];
      this.sidebarIsOpened = false;
    } else {
      this.sidebarIsOpened = false;
    }
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.sidebarToggleSubscription.unsubscribe();
    this.windowResizeSubscription.unsubscribe();
  }

  public onParentClick(itemIndex) {
    const findedItemIndex = this.activatedParentItems.indexOf(itemIndex);
    if (findedItemIndex > -1) {
      this.activatedParentItems.splice(findedItemIndex, 1);
    } else {
      if (this.sidebarIsMinified) {
        this.activatedParentItems = [];
      }
      this.activatedParentItems.push(itemIndex);
    }
  }

  public parentIsActive(parentIndex) {
    return this.activatedParentItems.indexOf(parentIndex) > -1;
  }

  public iconClass({text, items}: any): any {
    return {
      'k-i-file-pdf': this.hasExtension(text, 'pdf'),
      'k-i-folder': items !== undefined,
      'k-i-html': this.hasExtension(text, 'html'),
      'k-i-image': this.hasExtension(text, 'jpg|png'),
      'k-icon': true
    };
  }

  isActiveItem(item) {
    if (this.activeItem) {
      return item.path === this.activeItem.path;
    }

    if (this.router.url === item.path) {
      this.activeItem = item;
      return true;
    } else {
      if (this.urlHasPathWithParams(item.path)) {
        this.activeItem = item;
        return true;
      }

      return false;
    }
  }

  /**
   * Рекурсивно проверяем дочерние элементы
   * @param item
   */
  public getActiveSubItem(item) {
    if (this.isActiveItem(item)) {
      return item;
    }

    if (!item.items || item.items.length === 0) {
      return null;
    }

    let activeChild;
    item.items.forEach((submenu) => {
      const activeChildSubitem = this.getActiveSubItem(submenu);
      if (activeChildSubitem) {
        activeChild = activeChildSubitem;
        return;
      }
    });

    if (activeChild) {
      return activeChild;
    }

    return null;
  }

  setActiveParent() {
    if (!WindowHelper.isMobileWidth() && !this.sidebarIsOpened) {
      this.activatedParentItems = [];
      return;
    }

    this.menu.forEach((item) => {
      if (this.getActiveSubItem(item)) {
        this.activatedParentItems.push(item.path);
      }
    });
  }

  /**
   * Проверяем, не содержит ли url путь текущего элемента с параметром /id или /uid
   * @param path
   */
  private urlHasPathWithParams(path) {
    if (this.router.url.includes(path)) {
      return (path === this.router.url.replace(/\/\d+$/, ''))
        || (path === this.router.url.replace(/\/[a-z]{2}[\d]+-[\d]+$/i, ''));
    }

    return false;
  }

  /**
   * Установить Title по активному пункту меню
   */
  private setTitleFromMenu() {
    this.menu.forEach((item) => {
      const activeSubItem = this.getActiveSubItem(item);
      if (activeSubItem && activeSubItem.title) {
        this.title.setTitle(activeSubItem.title.replace(/<\/?[^>]+(>|$)/g, ' '));
      }
    });
  }

  public hasChildren(item: RouteInfo) {
    return item.items && item.items.length
  }
}
