import {ApiService} from './api.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DadataService extends ApiService {
  /**
   * Подсказки по адресу
   * @param params
   */
  public getSuggestions(params) {
    return this.get('/client/dadata/suggestions?' + this.getQueryStringFromArray(params));
  }
}
