import {ErrorHandler, Injectable} from '@angular/core';
import {BaseErrorHandlerService} from './base-error-handler.service';

@Injectable()
export class BaseErrorHandler implements ErrorHandler {
  constructor(private baseErrorHandlerService: BaseErrorHandlerService) {
  }

  handleError(error: any) {
    console.error(error);

    this.baseErrorHandlerService.sendError(error);
  }
}
