import {Component} from '@angular/core';
import {BaseFilterComponent} from './base-filter.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-ngx-datatable-filter-datepicker',
  template: `
    <form [formGroup]="formFilter">
      <mat-form-field (click)="picker.open()">
        <input autocomplete="off" matInput formControlName="filter" [matDatepicker]="picker"
               placeholder="Выберите дату" (dateChange)="triggerChange()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </form>
  `,
  styleUrls: ['./filters.scss']
})
export class FilterDatepickerComponent extends BaseFilterComponent {
  constructor(public datePipe: DatePipe) {
    super();
  }
}
