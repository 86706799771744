export class BaseModel {
  public id?: number;
  public created_at: Date;
  public updated_at: Date;

  constructor(data: any) {
    if (!data) {
      return;
    }
    for (const key of Object.keys(data)) {
      this[key] = data[key];
    }
  }
}
