import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency} from '@angular/common';

@Pipe({ name: 'ls_money' })
export class LsMoneyPipe implements PipeTransform {
  transform(value: number, removeFractional: boolean = false): string {
    if (removeFractional) {
      if ((Math.round(value) - value) === 0) {
        return formatCurrency(value, 'ru-RU', '', 'RUB', '1.0-0');
      }
    }

    return formatCurrency(value, 'ru-RU', '', 'RUB');
  }
}
