import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HubModel} from '../../models/hub.model';
import {ApiService} from '../../services/api.service';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-hub-select',
  templateUrl: './hub-select.component.html',
  styleUrls: ['./hub-select.component.scss']
})
export class HubSelectComponent implements OnInit {

  @Input() selectorControlName: string;
  @Input() selectorControlId: string;
  @Input() selectorClass: string;
  @Input() disabled: boolean = false;
  @Input() showFullName: boolean = false;

  @Input() parentFormControlName: string;
  @Input() parentFormGroup: FormGroup;

  @Output('onChange') emitToParent = new EventEmitter<HubModel>();

  public hubs: HubModel[];

  constructor(private apiService: ApiService) {
    this.apiService.getHubs()
      .subscribe(hubs => {
        this.hubs = hubs;
      })
  }

  ngOnInit(): void {
  }

  public onHubChange(event: Event) {
    const hubId = +(<HTMLSelectElement>event.target).value;

    this.emitToParent.emit(this.getHubById(hubId))
  }

  private getHubById(id: number) {
    return this.hubs.find(hub => hub.id === id);
  }
}
