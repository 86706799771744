import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class BaseErrorHandlerService {
    private http: HttpClient;

    constructor(
        private handler: HttpBackend
    ) {
        this.http = new HttpClient(handler);
    }

    public sendError(error: Error) {
        this.http.post(environment.apiUrl + '/client/handleError', {
                message: error.message,
                stack: error.stack,
                name: error.name,
            })
            .subscribe((response) => {});
    }
}
