import {Component} from '@angular/core';
import {BaseFilterComponent} from './base-filter.component';
import {OrdersApiService} from '../../orders/orders-api.service';
import {OrderStatusModel} from '../models/order-status.model';

@Component({
  selector: 'app-ngx-datatable-filter-order-status',
  template: `
    <form [formGroup]="formFilter">
      <select class="form-control form-control-sm" formControlName="filter" name="role" (change)="triggerChange()">
        <option value=""> === Все === </option>
        <option *ngFor="let status of orderStatusList" value="{{status.id}}">{{status.name}}</option>
      </select>
    </form>
  `
})
export class FilterOrderStatusComponent extends BaseFilterComponent {
  constructor(private api: OrdersApiService) {
    super();

    this.loadOrderStatuses();
  }

  public orderStatusList: OrderStatusModel[];

  loadOrderStatuses() {
    this.api.getOrderStatuses().subscribe((data) => {
      this.orderStatusList = data;
    })
  }
}
