import { Action } from '@ngrx/store';
import {UserModel} from '../../shared/models/user.model';
import {ClientModel} from '../../shared/models/client.model';
import {AccessTokenModel} from '../../shared/models/access-token.model';

export const LOGIN_START = '[Auth] Login Start';
export const AUTHENTICATE_SUCCESS = '[Auth] Authenticate Success';
export const AUTHENTICATE_FAIL = '[Auth] Authenticate Fail';
export const CLEAR_ERROR = '[Auth] Clear Error';
export const AUTO_LOGIN = '[Auth] Auto Login';
export const LOGOUT = '[Auth] Logout';
export const REFRESH_TOKEN = '[Auth] Refresh Token';
export const SET_SIDEBAR_MINIFIED = '[Auth] Set Sidebar Minified';
export const UPDATE_CLIENT_DATA = '[Auth] Update Client Data';

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(
    public payload: {
      user: UserModel;
      client: ClientModel;
      token: AccessTokenModel;
      redirect: boolean;
    }
  ) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LoginStart implements Action {
  readonly type = LOGIN_START;

  constructor(public payload: { login: string; password: string }) {}
}

export class AuthenticateFail implements Action {
  readonly type = AUTHENTICATE_FAIL;

  constructor(public payload: string) {}
}

export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
}

export class RefreshToken implements Action {
  readonly type = REFRESH_TOKEN;
}

export class AutoLogin implements Action {
  readonly type = AUTO_LOGIN;
}

export class SetSidebarMinified implements Action {
  readonly type = SET_SIDEBAR_MINIFIED;

  constructor(public payload: boolean) {}
}

export class UpdateClientData implements Action {
  readonly type = UPDATE_CLIENT_DATA;

  constructor(public payload: ClientModel) {}
}

export type AuthActions =
  | AuthenticateSuccess
  | Logout
  | LoginStart
  | AuthenticateFail
  | ClearError
  | RefreshToken
  | SetSidebarMinified
  | UpdateClientData
  | AutoLogin;
