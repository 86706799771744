import {NgModule} from "@angular/core";
import {AngularYandexMapsModule} from 'angular8-yandex-maps';
import {CommonModule} from "@angular/common";
import {MatSnackBar} from '@angular/material/snack-bar';
import {OverlayModule} from '@angular/cdk/overlay';
import {ReactiveFormsModule} from '@angular/forms';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FilterCheckboxComponent} from './ngx-datatable/filter-checkbox.component';
import {FilterIsSamedayComponent} from './ngx-datatable/filter-is-sameday.component';
import {FilterStringComponent} from './ngx-datatable/filter-string.component';
import {FilterDatepickerComponent} from './ngx-datatable/filter-datepicker.component';
import {FilterOrderStatusComponent} from './ngx-datatable/filter-order-status.component';
import {FilterTransceiverActStatusComponent} from './ngx-datatable/filter-transceiver-act-status.component';
import {FilterZorderStatusComponent} from './ngx-datatable/filter-zorder-status.component';
import {LsMoneyPipe} from './pipes/ls-money.pipe';
import {DialogComponent} from './components/dialog/dialog.component';
import { DadataAddressComponent } from './components/dadata-address/dadata-address.component';
import { HubSelectComponent } from './components/hub-select/hub-select.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {IMaskModule} from 'angular-imask';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxBarcodeModule } from 'ngx-barcode';
import {NgxDatatableHelper} from "./helpers/ngx-datatable.helper";
import {DropdownDirective} from './directives/dropdown.directive';
import {NgxDatatableRecalculateDirective} from './directives/ngx-datatable-recalculate.directive';
import {DatepickerAdapter} from './datepicker-adapter/datepicker-adapter';


@NgModule({
  declarations: [
    FilterCheckboxComponent,
    FilterIsSamedayComponent,
    FilterStringComponent,
    FilterDatepickerComponent,
    FilterOrderStatusComponent,
    FilterTransceiverActStatusComponent,
    FilterZorderStatusComponent,
    LsMoneyPipe,
    DialogComponent,
    DadataAddressComponent,
    HubSelectComponent,
    DropdownDirective,
    NgxDatatableRecalculateDirective,
  ],
  imports: [
    AngularYandexMapsModule,
    CommonModule,
    OverlayModule,
    ReactiveFormsModule,
    NgxBarcodeModule,
    NgxDatatableModule.forRoot({messages: NgxDatatableHelper.messages}),
    NgxMaterialTimepickerModule,
    IMaskModule,
    MaterialFileInputModule,

    // Mat modules
    MatDatepickerModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatTableModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatIconModule,
    MatExpansionModule,
    // /Mat modules
  ],
  exports: [
    AngularYandexMapsModule,
    CommonModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MaterialFileInputModule,
    NgxMaterialTimepickerModule,
    NgxBarcodeModule,

    // Mat modules
    MatDatepickerModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatTableModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatIconModule,
    MatExpansionModule,
    // /Mat modules

    FilterCheckboxComponent,
    FilterIsSamedayComponent,
    FilterStringComponent,
    FilterDatepickerComponent,
    FilterOrderStatusComponent,
    FilterTransceiverActStatusComponent,
    FilterZorderStatusComponent,
    LsMoneyPipe,
    DialogComponent,
    DadataAddressComponent,
    HubSelectComponent,
    IMaskModule,
    DropdownDirective,
    NgxDatatableRecalculateDirective,
  ],
  providers: [
    NgxDatatableHelper,
    MatSnackBar,
    [{provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}],
    {provide: DateAdapter, useClass: DatepickerAdapter},
  ]
})

export class SharedModule {

}
