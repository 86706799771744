import { Action } from '@ngrx/store';

export const SET_SIDEBAR_MINIFIED = '[User] Set sidebar minified';

export class SetSidebarMinified implements Action {
  readonly type = SET_SIDEBAR_MINIFIED;

  constructor(public payload: boolean) {}
}

export type UserActions =
  | SetSidebarMinified;
