import { UserModel } from '../../shared/models/user.model';
import * as AuthActions from './auth.actions';
import {ClientModel} from '../../shared/models/client.model';
import {AccessTokenModel} from '../../shared/models/access-token.model';

export interface State {
  user: UserModel;
  client: ClientModel;
  token: AccessTokenModel;
  authError: string;
  loading: boolean;
  sidebarIsMinified: boolean;
}

const initialState: State = {
  user: null,
  client: null,
  token: null,
  authError: null,
  loading: false,
  sidebarIsMinified: false
};

export function authReducer(
  state = initialState,
  action: AuthActions.AuthActions
) {
  switch (action.type) {
    case AuthActions.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        authError: null,
        user: action.payload.user,
        client: action.payload.client,
        token: action.payload.token,
        loading: false
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        user: null,
        client: null,
        token: null
      };
    case AuthActions.LOGIN_START:
      return {
        ...state,
        authError: null,
        loading: true
      };
    case AuthActions.AUTHENTICATE_FAIL:
      return {
        ...state,
        user: null,
        client: null,
        token: null,
        authError: action.payload,
        loading: false
      };
    case AuthActions.CLEAR_ERROR:
      return {
        ...state,
        authError: null
      };
    case AuthActions.SET_SIDEBAR_MINIFIED:
      return {
        ...state,
        sidebarIsMinified: action.payload
      };
    case AuthActions.UPDATE_CLIENT_DATA:
      return {
        ...state,
        client: action.payload
      };
    default:
      return state;
  }
}
