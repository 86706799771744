import {Directive, OnDestroy, OnInit} from '@angular/core';
import {EventerService} from '../services/eventer.service';
import {Subscription} from 'rxjs';
import {DatatableComponent} from '@swimlane/ngx-datatable';

@Directive({
  selector: '[appNgxDatatableRecalculate]'
})
export class NgxDatatableRecalculateDirective implements OnInit, OnDestroy {
  private sidebarToggleSubscription: Subscription;
  private windowResizeSubscription: Subscription;
  private recalculateTimeout;

  constructor(
    private table: DatatableComponent,
    private eventerService: EventerService
  ) {
  }

  ngOnDestroy(): void {
    if (this.sidebarToggleSubscription) {
      this.sidebarToggleSubscription.unsubscribe();
    }
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }

    this.clearTimeout();
  }

  ngOnInit(): void {
    this.sidebarToggleSubscription = this.eventerService.adminSidebarToggle
      .subscribe(state => {
        this.clearTimeout();

        this.recalculateTimeout = setTimeout(() => {
          this.table.recalculate();
        }, 500)
      })
    this.windowResizeSubscription = this.eventerService.windowResize
      .subscribe(state => {
        this.clearTimeout();

        this.recalculateTimeout = setTimeout(() => {
          this.table.recalculate();
        }, 500)
      })
  }

  clearTimeout() {
    if (this.recalculateTimeout) {
      clearTimeout(this.recalculateTimeout);
      this.recalculateTimeout = null;
    }
  }
}
