import {Component} from '@angular/core';
import {BaseFilterComponent} from './base-filter.component';
import {ZordersApiService} from '../../zorders/zorders-api.service';
import {ZorderStatusModel} from '../models/zorder-status.model';

@Component({
  selector: 'app-ngx-datatable-filter-zorder-status',
  template: `
    <form [formGroup]="formFilter">
      <select class="form-control form-control-sm" formControlName="filter" name="role" (change)="triggerChange()">
        <option value=""> === Все ===</option>
        <option *ngFor="let status of zorderStatusList" value="{{status.id}}">{{status.name}}</option>
      </select>
    </form>
  `
})
export class FilterZorderStatusComponent extends BaseFilterComponent {
  constructor(private api: ZordersApiService) {
    super();

    this.loadZorderStatuses();
  }

  public zorderStatusList: ZorderStatusModel[];

  loadZorderStatuses() {
    this.api.getZorderStatuses().subscribe((data) => {
      this.zorderStatusList = data;
    });
  }
}
