import {Component} from '@angular/core';
import {BaseFilterComponent} from './base-filter.component';

@Component({
  selector: 'app-ngx-datatable-filter-checkbox',
  template: `
    <form [formGroup]="formFilter">
      <select class="form-control form-control-sm" formControlName="filter" name="role" (change)="triggerChange()">
        <option value="" [selected]="null === value || '' === value"> === Все ===</option>
        <option value="0" [selected]="0 === value">Нет</option>
        <option value="1" [selected]="1 === value">Да</option>
      </select>
    </form>
  `
})
export class FilterCheckboxComponent extends BaseFilterComponent {
}
